import React from "react"
import pricesStyles from '../../style/prices.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import PlauderButton from "../global/plauderbtn.js"

const IndividualPackages = (props) => {

   
    function hideTitle (){
        if (props.mobile){
        return {display: "none"}
        }
    }
   
        return (
                <section>
                    <h2 style = {hideTitle()} ><FormattedMessage id="Prices.individualpackages.h" /></h2>
                    <h4 className={pricesStyles.pricesTagline}><FormattedMessage id="Prices.individualpackages.tagline" /></h4>
                    <div className={pricesStyles.individualText}>
                        <div><p><FormattedMessage id="Prices.individualpackages.p" /></p></div>
                        <div> <PlauderButton /></div>
                    </div>
                </section>

        )
}

export default IndividualPackages