import React, {useState, useRef} from "react"
import pricesStyles from '../../style/prices.module.scss'





const Accordion = ({title, content}) => {

    const [active, setActive] = useState(false)
    const refScrollPos = useRef(0)

    function turnBtn(){
        if(active){
           return {transform: "rotate(180deg)"}
        } else {
           return {transform: "rotate(0deg)"}
        }
    }

    function toggleContent(){
        if(active === true){
        window.scrollTo(0, refScrollPos.current.offsetTop - 150)
        setActive(false)
        }
        else {
            setActive(true)
        }
    }

    return (
        <div className={pricesStyles.accordionContainer} active={active} ref={refScrollPos} >
       <button onClick = {() => toggleContent()} className = {pricesStyles.accordionTitleBar}>
        <h2>{title}</h2>
            <div className = {pricesStyles.accordionBtn} style = {turnBtn()}/>
        </button>
        <div className = {pricesStyles.accordionContent} style = {active ? {display: "block"} : {display: "none"}}>
        {content}
        </div>
    
        </div>
    )
}

export default Accordion
 