import React from "react"
import pricesStyles from '../../style/prices.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Accordion from "./prices.accordion.js"
import IndividualPackages from "./prices.individualpackages.js"
import WebsitePackages from "./prices.websitepackages.js"
import Startprices from "./prices.startprices.js"

const PricesContent = ({ screenSize }) => {

    if (screenSize === "mobile") {
        return (
            <div className={pricesStyles.content}>
                <section>
                    <Accordion
                        title={
                            <FormattedMessage id="Prices.individualpackages.h" />
                        }

                        content={
                            <>
                                <IndividualPackages mobile/>
                            </>
                        }></Accordion>
                </section>

                <section>
                    <Accordion
                        title={
                            <FormattedMessage id="Prices.websitepackages.h" />
                        }
                        content={
                            <>
                                <WebsitePackages mobile/>
                            </>
                        }

                    ></Accordion>
                </section>

                <section>

                    <Accordion
                        title={<FormattedMessage id="Prices.startprices.h" />}
                        content={
                            <>
                                <Startprices mobile/>
                            </>
                        }
                    >
                    </Accordion>



                </section>

            </div>

        )
    } else {
        return (
            <div className={pricesStyles.content}>
                <IndividualPackages/>
                <WebsitePackages/>
                <Startprices/>
            </div>
        )
    }

}

export default PricesContent