import React, { useState } from "react"
import pricesStyles from '../../style/prices.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import PricingCard from "./pricingcard.js"


const WebsitePackages = (props) => {

    const [pricingSelection, setPricingSelection] = useState(0)

    function hideTitle (){
        if (props.mobile){
        return {display: "none"}
        }
    }

    function getClass(btnID) {
        if (btnID === pricingSelection) {
            return pricesStyles.toggleBtn_active
        } else {
            return pricesStyles.toggleBtn
        }
    }

    function getPrice(fullPrice, aboPrice) {
        if (pricingSelection === 0) {
            return fullPrice
        } else {
            return <span> {aboPrice} <FormattedMessage id="Prices.websitepackages.monthly" /></span>
        }
    }

    function getPackageText() {
        if (pricingSelection === 0) {
            return <span><FormattedMessage id="Prices.websitepackages.single.p1" />  <FormattedMessage id="Prices.websitepackages.single.p2" />  </span>
        } else {
            return <span><FormattedMessage id="Prices.websitepackages.abo.p1" /> <FormattedMessage id="Prices.websitepackages.abo.p2" />  </span>
        }
    }

    return (
        <section>
            <h2 style = {hideTitle()} ><FormattedMessage id="Prices.websitepackages.h" /></h2>
            <div className={pricesStyles.abo}>
                <h4 className={pricesStyles.pricesTagline}><FormattedMessage id="Prices.websitepackages.tagline" /></h4>
                <p><FormattedMessage id="Prices.websitepackages.p" /></p>
            </div>
            <div className={pricesStyles.toggleBtnContainer}>
                <button onClick={() => { setPricingSelection(0) }} className={getClass(0)}><FormattedMessage id="Prices.websitepackages.btn.singlepayment" /></button>
                <button onClick={() => { setPricingSelection(1) }} className={getClass(1)}><FormattedMessage id="Prices.websitepackages.btn.abo" /></button>
            </div>
            <div className={pricesStyles.webDescription}>
                {getPackageText()} </div>
            <div className={pricesStyles.pricingCardContainer}>
                <div className={pricesStyles.pricingCardContainerHalf}>
                    <PricingCard
                        format={"rect"}
                        title={
                            <FormattedMessage id="Prices.onepager.h" />
                        }
                        price={
                            getPrice("€500", "€25")
                        }
                        details={
                            <ul>
                                <li>1 <FormattedMessage id="Prices.page" /></li>
                                <li><FormattedMessage id="Prices.standardtemplate" /> </li>
                            </ul>
                        }
                    />
                    <PricingCard
                        delay={".2s"}
                        format={"rect"}
                        title={
                            <FormattedMessage id="Prices.starter" />
                        }
                        price={
                            getPrice("€1000", "€50")
                        }
                        details={
                            <ul>
                                <li>3 <FormattedMessage id="Prices.pages" /></li>
                                <li><FormattedMessage id="Prices.personalizedtemplate" /></li>
                                <span><li><FormattedMessage id="Prices.cms" /></li></span>
                            </ul>
                        } />
                </div>
                <div className={pricesStyles.pricingCardContainerHalf}>
                    <PricingCard
                        delay={".4s"}
                        format={"rect"}
                        title={
                            <FormattedMessage id="Prices.business" />
                        }
                        price={
                            getPrice("€2000", "€100")
                        }
                        details={
                            <ul>
                                <li>5 <FormattedMessage id="Prices.pages" /></li>
                                <li><FormattedMessage id="Prices.personalizedtemplate" /> </li>
                                <span><li><FormattedMessage id="Prices.cms" /> </li>
                                    <li><FormattedMessage id="Prices.contactform" /></li> </span>
                            </ul>
                        } />
                    <PricingCard
                        delay={".6s"}
                        format={"rect"}
                        title={
                            <FormattedMessage id="Prices.enterprise" />
                        }
                        price={
                            getPrice("€4000", "€200")
                        }
                        details={
                            <ul>
                                <li>10 <FormattedMessage id="Prices.pages" /> </li>
                                <li><FormattedMessage id="Prices.customtemplate" /> </li>
                                <span><li><FormattedMessage id="Prices.cms" /> </li>
                                    <li><FormattedMessage id="Prices.contactform" /></li>
                                    <li><FormattedMessage id="Prices.plugins" /></li></span>
                            </ul>
                        } />
                </div>
            </div>
        </section>
    )
}

export default WebsitePackages