import React from "react"
import pricesStyles from '../../style/prices.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import PricingCard from "./pricingcard.js"

const StartPrices = (props) => {
    function hideTitle (){
        if (props.mobile){
        return {display: "none"}
        }
    }
        return (
                <section>
                    <h2 style = {hideTitle()} ><FormattedMessage id="Prices.startprices.h" /></h2>
                    <h4 className={pricesStyles.pricesTagline}><FormattedMessage id="Prices.startprices.tagline" /></h4>
                    <p><FormattedMessage id="Prices.startprices.p" /></p>
                    <div className={pricesStyles.pricingCardContainerSquare}>
                        <PricingCard
                            format={"square"}
                            title={
                                <FormattedMessage id="Prices.startprices.apps" />
                            }
                            price={
                                "€2500"
                            }
                        />
                        <PricingCard
                            format={"square"}
                            title={
                                <FormattedMessage id="Prices.startprices.training" />
                            }
                            price={
                                "€800"
                            }

                            details={
                                <span>
                                    <FormattedMessage id="Prices.startprices.participant" /><br />
                                    <FormattedMessage id="Prices.startprices.day" />
                                </span>
                            }
                        />
                        <PricingCard
                            format={"square"}
                            title={
                                "Consulting"
                            }
                            price={
                                "€900"
                            }
                            details={
                                <FormattedMessage id="Prices.startprices.day" />
                            }
                        />
                    </div>
                </section>
        )
    }

export default StartPrices