import React, { useEffect, useState } from "react"
import pricesStyles from '../../style/prices.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { useInView} from "react-intersection-observer"
import styled from "styled-components"

const Flip = styled.div`
transform: ${({ animation }) => animation ? "rotateY(0deg);" : "rotateY(-180deg)"};
opacity: ${({ animation }) => animation ? "1" : "0"};
transition: transform 1s;
transition-delay: ${({ delay }) => delay};
overflow: hidden;
transform-style: preserve-3d;
-webkit-backface-visibility: hidden; /* Safari */
backface-visibility: hidden;
`

const PricingCard = ({ format, title, price, details, delay }) => {

    const [ref, inView] = useInView(.1)
    const [animation, setAnimation] = useState(false)
    const [wasTriggered, setWasTriggered] = useState(false)



    useEffect(() => {

        if(inView){
            if(!wasTriggered){
                setAnimation(true)
                setWasTriggered(true)
            } 
        }


    }, [ref, inView, wasTriggered])



    if (format === "rect") {
        return (
            <div style = {{perspective: "600px"}}>
            <Flip ref={ref} animation={animation} delay={delay}>
                <div className={pricesStyles.pricingCard} ref={ref}>
                    <h3>{title}</h3>
                    <p className={pricesStyles.pricingCardPrice}>{price}</p>
                    <div className={pricesStyles.pricingCardDetails}>{details}
                    </div>
                </div>
            </Flip>
            </div>

        )
    } else if (format === "square") {
        return (
            <div style = {{perspective: "600px"}}>
            <Flip ref={ref} animation={animation}>
                <div className={pricesStyles.pricingCardSquare}>
                <div>
                    <h3>{title}</h3>
                        <p className={pricesStyles.pricingCardSquareFrom}> <FormattedMessage id="Prices.startprices.from" /> </p>
                        <p className={pricesStyles.pricingCardSquarePrice}>{price}</p>
                    <span className={pricesStyles.pricingCardSquareDetail}>{details}</span>
                </div>
                </div>
            </Flip>
            </div>


        )
    }

}

export default PricingCard