import React, { useEffect, useState } from "react"
import Layout from '../components/global/layout.js'
import { FormattedMessage } from 'gatsby-plugin-intl'
import LandingSection from "../components/global/landingsection.js"
import ContentSection from "../components/global/contentsection.js"
import PricesContent from "../components/prices/prices.content.js"
import { Helmet } from "react-helmet"


const PricesPage = () => {

  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [width])

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [width])


  return (
    <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <title> Spherical Elephant | Preise </title>
        <meta name="description" content="Unsere Preise passen sich flexibel an deine Bedürfnisse an! | Individuelle Pakete | Website Flat-Rates inkl. Hosting und Wartung ab 25 € mtl."></meta>
      </Helmet>


      <LandingSection
        h1={<FormattedMessage id="Prices.h1" />}
        title={<FormattedMessage id="Prices.title" />}
        subtitle={<FormattedMessage id="Prices.subtitle" />}
      />

      <ContentSection>
        {width > 600 ? <PricesContent screenSize="desktop" /> : <PricesContent screenSize="mobile" />}
      </ContentSection>

    </Layout>

  )

}

export default PricesPage